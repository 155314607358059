import { atom, selector, useSetRecoilState } from "recoil";
import { guessLanguage } from "../helpers/guessLanguage";
import fetchJSON from "../helpers/fetchJSON";

/**
 * 
 */
export const appState = atom({
    key: "appState",
    default: {
        state: 'dev',
        version: '2.1.3',
        build: '1720685284',
    },
});

/**
 * 
 */
export const appLanguageState = atom({
    key: "appLanguageState",
    default: localStorage.getItem('language') ?? guessLanguage(),
});

/**
 * 
 */
export const apiTokenState = atom({
    key: "apiTokenState",
    default: localStorage.getItem('token') ?? null,
});

/**
 * 
 */
export const appDataVersionState = atom({
    key: "appDataVersionState",
    default: Date.now(),
});

/**
 * 
 */
export function useRefreshAppData() {
    const setState = useSetRecoilState(appDataVersionState);
    return () => {
        setState(Date.now());
    };
}

/**
 * 
 */
export const apiUrlState = atom({
    key: "apiUrlState",
    default: selector({
        key: 'apiUrlStateSelector',
        get: ({ get }) => {
            get(appDataVersionState)
    
            switch (get(appState).state) {
                case 'prod':
                    return 'https://app.haetrackr.org';
                default:
                    return 'https://beta.haetrackr.org';
            }
        }
    }),
});

/**
 * 
 */
export const appMessagesState = selector({
    key: 'appMessagesState',
    get: async ({ get }) => {
        get(appDataVersionState)
        if (get(appState).state !== 'prod') {
            console.debug('data: appMessagesState')
        }

        return await fetchJSON({
            token: get(apiTokenState),
            language: get(appLanguageState)
        }).get(`${get(apiUrlState)}/api/?r=app/messages`)
    }
})

/**
 * 
 */
export const appLanguagesState = selector({
    key: 'appLanguagesState',
    get: async ({ get }) => {
        get(appDataVersionState)
        if (get(appState).state !== 'prod') {
            console.debug('data: appLanguagesState')
        }

        const data = await fetchJSON({
            token: get(apiTokenState),
            language: get(appLanguageState)
        }).get(`${get(apiUrlState)}/api/?r=app/user-options&attributes=languages`)

        return data?.languages ?? []
    }
})

/**
 * 
 */
export const appOptionsState = selector({
    key: 'appOptionsState',
    get: async ({ get }) => {
        get(appDataVersionState)
        if (get(appState).state !== 'prod') {
            console.debug('data: appOptionsState')
        }

        return await fetchJSON({
            token: get(apiTokenState),
            language: get(appLanguageState)
        }).get(`${get(apiUrlState)}/api/?r=app/options`)
    },
});

/**
 * 
 */
export const appNotificationsState = selector({
    key: 'appNotificationsState',
    get: async ({ get }) => {
        get(appDataVersionState)
        if (get(appState).state !== 'prod') {
            console.debug('data: appNotificationsState')
        }

        return await fetchJSON({
            token: get(apiTokenState),
            language: get(appLanguageState)
        }).get(`${get(apiUrlState)}/api/?r=notification/index`)
    },
});
